<template>
  <div>
    <!-- 头部 NavBar -->
    <van-nav-bar left-arrow @click-left="$router.go(-1)" title="积分充值" fixed>
    </van-nav-bar>
    <!-- 消息通知 积分不足提示 -->
    <van-notify v-model="ntfShow" type="warning">
      <van-icon name="bell" style="margin-right: 4px;" />
      <span>{{ this.message }}</span>
    </van-notify>
    <!-- 支付的sheet -->
    <van-action-sheet v-model="sheetShow" title="选择支付方式" class="pay-sheet">
      <div class="content" v-if="this.selected != null">
        <div>
          <div class="title">
            <!-- <span>充值金额</span> -->
            <span style="font-size: xxx-large;">￥{{ this.selected.pay.amount }}</span>
            <span>元</span>
          </div>
          <div class="description" style="font-size: large;">{{ this.selected.name }}</div>
          <van-radio-group v-model="payMethod" direction="horizontal" style="justify-content: center;" disabled>
            <van-radio name="3" checked-color="#ee0a24">
              <template #default>
                <img class="wxpay" :src="wxpay" alt="">
              </template>
            </van-radio>
            <van-radio name="6" checked-color="#ee0a24">
              <template #default>
                <img class="alipay" :src="alipay" alt="">
              </template>
            </van-radio>
          </van-radio-group>
          <div class="pay-btn">
            <van-button :loading="polling" loading-text="支付中。。。" round block class="" type="danger" @click="pay" :disabled="payDisable || polling">立 即 支 付</van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <!-- 充值列表 -->
    <div class="margin-nav-fixed">
      <van-cell-group inset v-for="(charge, index) in charges" :key="index" title=" ">
        <van-cell :label="charge.name">
          <template #default>
            <van-button type="primary" size="small" @click="recharge(charge)">充值</van-button>
          </template>
          <template #title>
            <span class="price">{{ charge.pay.amount_str }}</span>
            <span class="line_price">{{ charge.pay.price_str }}</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getRechargeList, orderPoints, checkOrder } from '@/api/pay'
import wxpay from '@/assets/wxpay.png'
import alipay from '@/assets/alipay.png'

export default {
  name: 'PayIndex',
  data () { // 数据
    return {
      // 支付图片，微信、支付宝
      wxpay: wxpay,
      alipay: alipay,
      payDisable: true, // 立即支付按钮是否禁用
      payMethod: '6', // 支付方式，支付宝(xorpay)
      charges: [], // 充值列表
      message: '', // 提示消息
      ntfShow: false, // 是否显示顶部消息
      notifyType: ['积分不足，请先充值'], // 顶部消息列表
      sheetShow: false, // 支付界面
      selected: null, // 选择的支付列表项
      polling: false, // 是否在轮询支付结果
      pollingTimes: 10, // 限制轮询次数终结
      pollingCount: 0, // 轮询计数器
      timeout: 3000, // 轮询间隔
      timer: null, // 轮询定时器
      response: [] // 下单后返回结果，包含拉起支付地址，status状态等
    }
  },
  watch: {
    message (newVal, oldVal) {
      if (newVal) {
        this.ntfShow = true
        this.message = newVal
        setTimeout(() => {
          this.ntfShow = false
        }, 2000)
      }
    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.getRechargeList()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {
    this.message = this.notifyType[parseInt(this.$route.query.ntfType)]
  },
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {
    this.stopPolling()
  },
  methods: {
    async getRechargeList () {
      await getRechargeList().then(res => {
        this.charges = res
      }).catch(err => {
        console.log('getRechargeList ERROR::::', err)
      })
    },
    recharge (chargeItem) { // 点击充值项
      this.selected = chargeItem
      this.payDisable = false
      this.sheetShow = true
    },
    async pay () {
      const name = this.selected.name
      const amount = this.selected.pay.amount
      const platform = this.payMethod
      const data = {
        name,
        amount,
        platform
      }
      if (this.payMethod === '6') {
        await orderPoints(data).then(res => {
          if (res.status === 'ok') {
            this.payDisable = true
            res.platform = '6'
            this.response = res
            // 拉起支付
            window.location.href = res.info.qr
            // 轮询支付结果
            this.startPolling()
          } else {
            this.$toast.fail('支付失败～！status:' + res.status + ', ' + res.info ?? '' + '; 请情重试或与客服联系！')
            this.sheetShow = false
          }
        })
      } else {
        // window.open(this.selected.wxpayUrl)
      }
      // this.sheetShow = false
    },
    // 开始轮询查询支付结果
    startPolling () {
      this.polling = true
      this.timer = setInterval(async () => {
        await checkOrder(this.response.platform, this.response.platform_no).then(res => {
          console.log(res)
          const state = res.status
          if (state === 'payed' || state === 'success') { // 支付成功逻辑
            this.$toast.success('支付成功～！')
            this.stopPolling()
          }
          this.pollingCount++
          if (this.pollingCount === 10) {
            this.$toast.fail('支付失败～！或网络有堵塞，请查询订单。')
            this.stopPolling()
            this.$router.push('/profile/recharges')
          }
        })
      }, this.timeout) // 每 3 秒查询一次
    },
    // 停止轮询查询支付结果
    stopPolling () {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.payDisable = true
      this.polling = false
      this.sheetShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.pay-sheet {
  .content {
    margin: 50px 16px;
    text-align: center;
  }
  .title {
    margin: 10px;
    font-size: 14px;
    color: brown;
  }
  .description {
    margin: 10px 10px 35px 10px;
    color: slategray;
  }
  .pay-btn {
    margin: 16px;
  }
}
.wxpay {
  width: 100px;
}
.alipay {
  height: 22px;
}
</style>
