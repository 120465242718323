<template>
  <div>
    <van-image class="avatar-img" :src="avatar ? avatar.src : ''" fit="cover" @click="show = true" round/>
    <div style="color: darkgray; text-align: right; position: relative; top: -15px;" @click="show = true">编辑</div>
    <van-dialog class="selectDialog" v-model="show" title="请选择" show-cancel-button @confirm="onConfirm">
        <van-row>
            <van-col span="6" v-for="(item, index) in avatarList" :key="index">
                <van-badge>
                    <van-image class="avatar-img" :src="item.src" @click="onSelected(index)" fit="cover" round/>
                    <template v-if="item.checked" #content>
                        <van-icon name="success" class="badge-icon" />
                    </template>
                </van-badge>
            </van-col>
        </van-row>
    </van-dialog>
  </div>
</template>

<script>
// import { uploadAvatar } from '@/api/user'

export default {
  components: {},
  data () {
    return {
      show: false,
      avatars: []
    }
  },
  props: {
    avatar: {
      type: Object,
      default: () => {}
    },
    avatarList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    avatarList: {
      handler (newVal, oldVal) {
        this.avatars = newVal
      },
      deep: true
    }
  },
  computed: {
    // avatarSrc () {
    //   return this.avatar ?
    // }
  },
  methods: {
    onSelected (i) {
      this.avatars.forEach(element => {
        element.checked = false
      })
      this.avatars[i].checked = true
    },
    onConfirm () {
      const selected = this.avatars.find(element => element.checked === true)
      this.$emit('updateAvatar', selected)
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-img {
    width: 100%;
    // height: 128px;
    aspect-ratio: 1/1;
}
.selectDialog {
    .van-row {
        height: 300px;
        overflow: scroll;
    }
    .van-col {
        padding: 10px;
    }
    .badge-icon {
    display: block;
    font-size: 10px;
    line-height: 16px;
    }
}

</style>
