<template>
  <div>
    <div class="search-bar van-hairline--bottom">
      <div class="search-bar-content">
        <div class="content-left" @click="$router.go(-1)">
          <van-icon name="arrow-left"/>
        </div>
        <div class="search-item">
          <van-search v-model="keyword" placeholder="请输入搜索关键词" show-action clearable @search="onSearch" @clear="onSearch">
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </div>
      </div>
    </div>
    <!-- 搜索结果列表 -->
    <van-empty v-if="list.length === 0" image="search" description="没有更多了" />
    <div v-else class="margin-nav-fixed" style="padding: 0 10px;">
      <MovieList title="搜索结果" :list="list" @onLoad="onSearchMore" :finished="finished" small />
      <!-- <div class="van-doc-demo-block">
        <h3 class="van-doc-demo-block__title">搜索结果</h3>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onSearch(false)" class="margin-btm50">
        <van-col span="24" class="van-col--24 col-24--padding" v-for="item in list" :key="item.id" @click="$router.push(`/movie/${item.id}`)">
          <ListItem :item="item" score/>
        </van-col>
      </van-list> -->
    </div>
  </div>
</template>

<script>
import { getSearch } from '@/api/search'
// import ListItem from '@/components/movie_list_item'
import MovieList from '@/components/movie_list'

export default {
  name: 'SearchIndex',
  data () { // 数据
    return {
      page: 0,
      keyword: '',
      // loading: false,
      finished: false,
      list: []
    }
  },
  // 组件
  components: {
    // ListItem,
    MovieList
  },
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.onSearch()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async onSearch (reload = true) {
      // reload ? this.page = 1 : this.page++
      // const search = this.keyword.split(' ')
      // const res = await getSearch(search, this.page)
      // // 如果是第一页，则清空list
      // if (this.page === 1) {
      //   this.list = []
      // }
      // if (res.length) {
      //   this.list = this.list.concat(res)
      //   // 加载状态结束
      //   this.loading = false
      // } else {
      //   this.finished = true
      // }
      this.page = 0
      this.finished = false
      this.list = []
      this.onSearchMore()
    },
    async onSearchMore () {
      this.page++
      const search = this.keyword.split(' ')
      const res = await getSearch(search, this.page)
      if (res.length) {
        this.list = this.list.concat(res)
        // 加载状态结束
        this.loading = false
      } else {
        this.finished = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-bar {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 100;
  .search-bar-content {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-align-items: center;
    height: 54px;
    background-color: #fff;
    .content-left {
      // position: absolute;
      top: 0;
      bottom: 0;
      // display: -webkit-box;
      // display: -webkit-flex;
      // display: flex;
      // -webkit-box-align: center;
      // -webkit-align-items: center;
      // align-items: center;
      padding: 0 4.26667vw;
      font-size: 3.73333vw;
      cursor: pointer;
      .van-icon-arrow-left {
        font-size: 16px;
        color: #1989fa;
      }
    }
    .search-item {
      max-width: 80%;
      width: 300px;
    }
  }

}
</style>
