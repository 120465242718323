/**
 *
 * 放置一些公共方法
 * @returns
 */

// 转换万或千为单位的数量
export const formatterCount = (count) => {
  return count >= 100000 ? ((count / 10000).toFixed(1) + 'w+') : (count >= 10000 ? (count / 1000).toFixed(1) + 'k+' : count)
}

// export const sourceUrl = 'http://devmyapp.ydns.eu/'
// export const sourceUrl = 'http://192.168.1.62/'
export const sourceUrl = 'https://m.1234u.top/'
