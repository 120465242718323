import Vue from 'vue'
import { Search, NavBar, Icon, Swipe, SwipeItem, Button, Tabbar, TabbarItem, NoticeBar, Grid, GridItem, List, Image as VanImage, Lazyload, Col, Row, PullRefresh, Empty, Card, Cell, CellGroup, Form, Field, Calendar, Rate, Tag, Badge, ActionSheet, Switch, Overlay, Notify, Divider, RadioGroup, Radio, Dialog, Toast } from 'vant'

// 全局注册
Vue.use(Toast)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Divider)
Vue.use(Notify)
Vue.use(Overlay)
Vue.use(Switch)
Vue.use(ActionSheet)
Vue.use(Badge)
Vue.use(Tag)
Vue.use(Rate)
Vue.use(Calendar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Card)
Vue.use(Empty)
Vue.use(PullRefresh)
Vue.use(Dialog)
Vue.use(Col)
Vue.use(Row)
Vue.use(Lazyload)
Vue.use(Search)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NoticeBar)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(List)
Vue.use(VanImage)
