<template>
    <div class="home">
      <!-- 头部 Navbar -->
      <van-nav-bar @click-right="rightClick" fixed>
        <template #title>
          <van-search shape="round" placeholder="点击搜索" @focus="$router.push('/search')" />
        </template>
        <template #left>
          <img id="logo" :src="logo" alt="title" />
        </template>
        <template #right>
          <div v-if="!token">登陆</div>
          <van-icon v-else name="weapp-nav" />
        </template>
      </van-nav-bar>

      <!-- 轮番 swipe -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in swipes" :key="item.id">
          <van-image v-if="item.movie_id" lazy-load width="100%" height="100%" fit="fill" :src="$baseUrl + item.image" @click="$router.push(`/movie/${item.movie_id}`)" />
          <a v-else :href="item.url"><van-image lazy-load width="100%" height="100%" fit="fill" :src="$baseUrl + item.image"></van-image></a>
        </van-swipe-item>
      </van-swipe>

      <!-- 通知 notice-->
      <van-notice-bar left-icon="volume-o" :text="notice" />

      <!-- 分类 category -->
      <van-grid class="categoryGrid background_color_white" clickable :column-num="5">
        <van-grid-item v-for="(value, key) in categories" :key="key" :text="value" @click="quickCategory(key)" />
        <!-- <van-grid-item v-for="(value, key) in categories" :key="key" :text="value" :to="`/category?c=${key}`" /> -->
        <van-grid-item text="更多>>" to="/category" />
      </van-grid>

      <!-- 今日推荐 today -->
      <div class="van-doc-demo-block">
        <h3 class="van-doc-demo-block__title">今日推荐</h3>
      </div>
      <van-row>
        <van-col span="24" class="col-24--padding" v-for="item in today" :key="item.id" @click="$router.push(`/movie/${item.id}`)">
          <ListItem :item="item" flag="推荐" score/>
        </van-col>
      </van-row>

      <!-- 猜你喜欢 -->
      <MovieList title="猜你喜欢" :list="uLike" @onLoad="onLoadPage" :finished="finished" small/>
    </div>
  </template>

<script>
import logo from '@/assets/logo.png'
import { getHomeData, getULike } from '@/api/home'
import ListItem from '@/components/movie_list_item'
import MovieList from '@/components/movie_list'
import store from '@/store'
// import { sourceUrl } from '@/utils/commonFn'

export default {
  name: 'HomeIndex',
  data () { // 数据
    return {
      logo: logo,
      notice: '',
      swipes: [],
      categories: [],
      today: [],
      uLike: [],
      uLikePage: 0,
      loading: false,
      finished: false,
      token: store.getters.token
    }
  },
  // 组件
  components: {
    ListItem,
    MovieList
  },
  // 计算属性
  computed: {
    // fullPath () {
    //   return sourceUrl
    // }
  },
  // 创建阶段
  beforeCreate () {},
  created () {
    this.getHomeData()
    this.onLoadPage()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async getHomeData () {
      try {
        const { swipes, categories, notice, today } = await getHomeData()
        this.swipes = swipes
        this.categories = categories
        this.notice = notice
        this.today = today
      } catch {}
    },
    async onLoadPage () {
      this.uLikePage++
      const res = await getULike(this.uLikePage)
      if (res && res.length) {
        this.uLike = this.uLike.concat(res)
        // 加载状态结束
        // this.loading = false
      } else {
        this.finished = true
      }
    },
    rightClick () {
      if (this.$store.getters.token) {
        this.$router.push('/profile')
        return
      }
      this.$router.replace('/login')
    },
    quickCategory (key) {
      this.$store.commit('setShouldReflashCategory', key)
      this.$router.push('/category')
    }
  }
}
</script>

<style lang="less" scoped>
  #logo {
    width: 30px;
  }
  .my-swipe .van-swipe-item {
    height: 180px;
  }
  .van-search {
    height: 46px;
    .van-search__content.van-search__content--round {
      height: 30px;
    }
  }

</style>
