<template>
  <div>
    <van-nav-bar title="修改密码" left-arrow @click-left="onClickLeft" fixed />
    <div class="margin-nav-fixed">
        <van-form @submit="onSubmit">
            <van-field
                v-model="email"
                name="email"
                label="注册邮箱"
                placeholder="用户名"
                readonly
            />
            <!-- <van-field
                v-model="oldPassword"
                type="password"
                name="olePassword"
                label="旧密码"
                placeholder="输入旧密码"
                required
                :rules="[{ required: true, message: '请填写旧密码' }]"
            /> -->
            <van-field
                v-model="newPassword"
                type="password"
                name="newPwd"
                label="新密码"
                required
                placeholder="输入新密码"
                :rules="[{ required: true, message: '请填写新密码' }]"
            />
            <van-field
                v-model="confirm_password"
                type="password"
                name="confirmPwd"
                label="确认密码"
                required
                placeholder="输入确认密码"
                :rules="[{ required: true, message: '请填写确认密码' }, { validator: this.validatePass, message: '两次输入的密码不匹配'}]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
  </div>
</template>

<script>
import { getEmail, setEmail } from '@/utils/storage'
import { updatePws } from '@/api/profile'

export default {
  name: 'UpdatePws',
  data () { // 数据
    return {
      email: '',
      oldPassword: '',
      newPassword: '',
      confirm_password: ''
    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    // 获取邮箱
    let email = getEmail()
    if (email) {
      this.email = email
    } else {
      email = this.$store.getters.email
      this.email = email
      setEmail(email)
    }
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async onSubmit (values) {
      updatePws(values.newPwd, values.confirmPwd).then(async res => {
        if (res === 'SUCCESS') {
          // clearEmail()
          this.$store.commit('user/logout')
          this.$toast('修改成功')
          this.$router.replace('/login?backPath=/profile')
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onClickLeft () {
      // clearEmail()
      this.$router.back()
    },
    validatePass () {
      if (this.newPassword !== this.confirm_password) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="less" scoped>

</style>
