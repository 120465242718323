<template>
  <div>
    <van-nav-bar title="输入邮箱验证码" left-arrow @click-left="$router.go(-1)" fixed />
    <div class="margin-nav-fixed">
        <van-form @submit="onSubmit">
            <!-- <van-field
                v-model="verifyCode"
                name="code"
                label="邮箱验证码"
                placeholder="请输入邮箱验证码"
                :rules="[{ required: true, message: '请填写有效的邮箱验证码' }]"
            >
                <template #button>
                    <van-button size="mini" type="primary" plain hairline round :disabled="isSend">{{ isSend ? '重新发送('+second+')' : '发送验证码' }}</van-button>
                </template>
            </van-field> -->

            <ValidateField title="已向注册邮箱发送验证码，验证码有效时间24小时。" v-model="verifyCode" reSendUrl="/email/verification-notification" :totalSecond="120" tip="验证码可能会延时，请注意查收。如一直没收到，请查看垃圾邮件或点击：" :shout="isShoutCode"/>

            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
  </div>
</template>

<script>
import { verificationEmail } from '@/api/auth'
import ValidateField from '@/components/validate-field.vue'

export default {
  name: 'EnterCode',
  data () { // 数据
    return {
      verifyCode: '',
      isShoutCode: true,
      email: '',
      isSend: false,
      second: 120,
      timer: null
    }
  },
  // 组件
  components: { ValidateField },
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {},
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
    this.isSend = false
  },
  destroyed () {},
  methods: {
    async onSubmit () {
      if ((this.isShoutCode && this.verifyCode.length === 4) || (!this.isShoutCode && this.verifyCode.length === 6)) {
        verificationEmail(this.verifyCode).then(res => {
          this.$toast.success('验证成功')
          const backPath = this.$route.query.backPath || ''
          if (backPath) {
            this.$router.push(backPath)
          } else {
            this.$router.push('/home')
          }
        }).catch(e => {
          this.$toast.fail('验证失败，请检查验证码是否正确。')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
