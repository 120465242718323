// 存放用户相关登陆，授权相关的接口请求
import store from '@/store'
import request from '@/utils/request'

export const getHomeData = () => {
  return request.get('/page/home')
}

export const getULike = (page) => {
  const history = store.getters.history
  return request.post('/get/ulike', {
    ...history,
    page
  })
}
