<template>
  <div>
    <!-- 头部 Navbar -->
    <van-nav-bar title="视频分类" fixed />

    <van-pull-refresh v-model="isLoading" @refresh="initList">
      <!-- 条件选项 -->
      <div class="padding-lr-16">
        <!-- 分类选项 -->
        <van-row>
          <van-col class="padding5px" v-for="(category, i) in categories" :key="category.id">
            <van-button round size="mini" type="info" :plain="!category.plain" hairline @click="cateClick('c',i)">{{ category.name }}</van-button>
          </van-col>
        </van-row>
        <!--  区域选项 -->
        <van-row>
          <van-col class="padding5px" v-for="(region, i) in regions" :key="region.id">
            <van-button round size="mini" type="primary" :plain="!region.plain" hairline @click="cateClick('r', i)">{{ region.name }}</van-button>
          </van-col>
        </van-row>
        <!-- 年份选项 -->
        <van-row>
          <van-col class="padding5px" v-for="(item, i) in year" :key="item.id">
            <van-button round size="mini" type="warning" :plain="!item.plain" hairline @click="cateClick('y', i)">{{ item.name }}</van-button>
          </van-col>
        </van-row>
        <!-- 关键词选项 -->
        <van-row>
          <van-col class="padding5px" v-for="(keyword, i) in keywords" :key="keyword.id">
            <van-button round size="mini" type="danger" :plain="!keyword.plain" hairline @click="cateClick('k', i)">{{ keyword.name }}</van-button>
          </van-col>
        </van-row>
      </div>

      <!-- <van-divider /> -->

      <!-- 搜索结果 -->
      <div>
        <MovieList title="搜索结果" :list="list" @onLoad="onLoadPage" :finished="finished" small />
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getAllCategories, getCategoriesSearchList } from '@/api/category'
// import ListItem from '@/components/movie_list_item'
import MovieList from '@/components/movie_list'
import { setHistory } from '@/utils/storage'

export default {
  name: 'CategoryList',
  data () { // 数据
    return {
      page: 0,
      categories: [],
      regions: [],
      year: [],
      keywords: [],
      finished: false,
      // loading: false,
      isLoading: false,
      list: []
    }
  },
  // 组件
  components: {
    // ListItem,
    MovieList
  },
  // 计算属性
  computed: {},
  activated () {
    if (this.$store.state.shouldReflashCategiry && this.categories.length > 0) {
      this.initList()
      this.$store.commit('setShouldReflashCategoryFalse')
    }
  },
  watch: {

  },
  // 创建阶段
  beforeCreate () {},
  async created () {
    await this.allCategories()
    this.initList()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async allCategories () {
      // const { categories, regions, year, keywords } = await getAllCategories()
      await getAllCategories().then((res) => {
        this.categories = res.categories.map((item) => {
          item.plain = false
          return item
        })
        this.regions = res.regions.map((item) => {
          item.plain = false
          return item
        })
        this.year = res.year.map((item) => {
          item.plain = false
          return item
        })
        this.keywords = res.keywords.map((item) => {
          item.plain = false
          return item
        })
      })
    },
    initList () {
      this.page = 0
      this.list = []
      this.finished = false
      this.loading = false
      this.isLoading = false
      const _key = this.$store.state.shouldReflashCategoryKey
      this.initSelection()
      this.onLoadPage(parseInt(_key))
    },
    initSelection () {
      this.categories.forEach(item => {
        item.plain = false
      })
      this.regions.forEach(item => {
        item.plain = false
      })
      this.year.forEach(item => {
        item.plain = false
      })
      this.keywords.forEach(item => {
        item.plain = false
      })
    },
    async getList (page = 1) {
      const categories = this.categories.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const regions = this.regions.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const year = this.year.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const keywords = this.keywords.filter(item => item.plain === true).map(item => {
        return item.name
      })
      return await getCategoriesSearchList(page, { categories: categories, regions: regions, year: year, keywords: keywords })
    },

    async onLoadPage (_key = 0) {
      this.page++
      if (_key) {
        const i = this.categories.indexOf(this.categories.filter(item => item.id === _key)[0])
        this.cateClick('c', i)
        return
      }
      // const res = await this.getList(this.page)
      await this.getList(this.page).then(res => {
        if (res.length) {
          this.list = this.list.concat(res)
          // 加载状态结束
          // this.loading = false
        } else {
          this.finished = true
        }
      })
    },
    async cateClick (slug, i) {
      // this.loading = false
      this.finished = false
      this.list = []
      this.page = 0

      switch (slug) {
        case 'c' :
          this.categories[i].plain = !this.categories[i].plain
          break
        case 'r' :
          this.regions[i].plain = !this.regions[i].plain
          break
        case 'y' :
          this.year[i].plain = !this.year[i].plain
          break
        case 'k' :
          this.keywords[i].plain = !this.keywords[i].plain
          break
      }

      const categories = this.categories.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const regions = this.regions.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const year = this.year.filter(item => item.plain === true).map(item => {
        return item.id
      })
      const keywords = this.keywords.filter(item => item.plain === true).map(item => {
        return item.name
      })
      setHistory({ categories: categories, regions: regions, year: year, keywords: keywords })
      this.onLoadPage()
      // this.list = await this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
