/**
 * 分类页相关请求
 */
import request from '@/utils/request'

/**
 * 获取所有分类
 * @returns {categories, regions, year, keywords}
 */
export const getAllCategories = () => {
  return request.get('/page/category')
}

/**
 * 按条件获取影片列表
 * @param {*} page Integer
 * @param {*} obj {categories, regions, year, keywords}
 * @returns Array
 */
export const getCategoriesSearchList = (page, obj) => {
  return request.post('/get/categories', {
    ...obj,
    page,
    search: []
  })
}
