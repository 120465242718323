<template>
  <div class="g-remove-check-code">
    <p class="g-remove-check-code_title" v-html="title"></p>
    <div class="g-remove-check-code_content"
            @keyup="fnCheckCodeKeyup"
            @keydown="fnCheckCodeKeydown"
            @paste="fnCheckCodeKeyPaste"
            @input="fnCheckCodeInputEvent"
    >
        <input v-for="i in codeLength" :key="i" :class="{'g-code-input_color': aCheckCodeInputComputed[i-1] !== ''}" max="9" min="0" maxlength="1" :data-index="i-1" v-model.trim.number="aCheckCodeInputComputed[i-1]" type="number" :ref="i === 1 ? 'firstInputRef' : ''" />
        <!-- <input :class="{'g-code-input_color': aCheckCodeInputComputed[1] !== ''}" max="9" min="0" maxlength="1" data-index="1" v-model.trim.number="aCheckCodeInputComputed[1]" type="number" />
        <input :class="{'g-code-input_color': aCheckCodeInputComputed[2] !== ''}" max="9" min="0" maxlength="1" data-index="2" v-model.trim.number="aCheckCodeInputComputed[2]" type="number" />
        <input :class="{'g-code-input_color': aCheckCodeInputComputed[3] !== ''}" max="9" min="0" maxlength="1" data-index="3" v-model.trim.number="aCheckCodeInputComputed[3]" type="number" />
        <input :class="{'g-code-input_color': aCheckCodeInputComputed[4] !== ''}" max="9" min="0" maxlength="1" data-index="4" v-model.trim.number="aCheckCodeInputComputed[4]" type="number" />
        <input :class="{'g-code-input_color': aCheckCodeInputComputed[5] !== ''}" max="9" min="0" maxlength="1" data-index="5" v-model.trim.number="aCheckCodeInputComputed[5]" type="number" /> -->
    </div>
    <p class="g-remove-check-code_tip">{{ aTip }} <span :class="reSendClass" @click="resend">{{ isSend ? second+'秒后重新发送' : '重新发送' }}</span></p>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  data () {
    return {
    //   aCheckCodeInput: [], // 存储输入验证码内容
      aCheckCodeInput: ['', '', '', ''], // 存储输入验证码内容
      aCheckCodePasteResult: [], // 粘贴的验证码
      second: 60, // 倒计时
      isSend: true, // 是否发送验证码
      timer: null // 定时器
    }
  },
  watch: {
    aCheckCodeInput (val) {
      if (val.join('').length === this.codeLength) {
        // console.log(val.join(''))
        this.$emit('input', val.join(''))
      }
    }
  },
  computed: {
    // 验证码计算属性
    aCheckCodeInputComputed () {
      if (this.shout) {
        if (this.aCheckCodePasteResult.length === 4) {
          return this.aCheckCodePasteResult
        } else if (this.aCheckCodeInput && Array.isArray(this.aCheckCodeInput) && this.aCheckCodeInput.length === 4) {
          return this.aCheckCodeInput
        } else if (/^\d{4}$/.test(this.aCheckCodeInput.toString())) {
          return this.aCheckCodeInput.toString().split('')
        } else {
          return new Array(4)
        }
      } else {
        if (this.aCheckCodePasteResult.length === 6) {
          return this.aCheckCodePasteResult
        } else if (this.aCheckCodeInput && Array.isArray(this.aCheckCodeInput) && this.aCheckCodeInput.length === 6) {
          return this.aCheckCodeInput
        } else if (/^\d{6}$/.test(this.aCheckCodeInput.toString())) {
          return this.aCheckCodeInput.toString().split('')
        } else {
          return new Array(6)
        }
      }
    },
    aTip () {
      return this.tip || `请输入${this.codeLength}位数验证码`
    },
    codeLength () {
      return this.shout ? 4 : 6
    },
    reSendClass () {
      return this.isSend ? 'code_tip_span_disabled' : 'code_tip_span'
    },
    sessionStorageKey () {
      return 'app_user_validata_code__resend_time_down_key__'
    }
  },
  props: {
    code: {
      type: Object,
      default: null
    },
    shout: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '已向注册邮箱发送验证码'
    },
    tip: {
      type: String,
      default: ''
    },
    totalSecond: {
      type: Number,
      default: 60
    },
    reSendUrl: {
      type: String,
      default: ''
    }
  },
  created () {
    // 防刷新
    const endTime = sessionStorage.getItem(this.sessionStorageKey)
    if (endTime) {
      const second = Math.ceil((JSON.parse(endTime) - new Date().getTime()) / 1000)
      if (second > 0) {
        this.second = second
        this.timerDown()
      } else {
        this.second = this.totalSecond
        this.isSend = false
      }
    } else { //  如果没有endTime
      this.second = Math.ceil(this.totalSecond / 2)
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(new Date().getTime() + this.second * 1000))
      this.timerDown()
    }
  },
  beforeDestroy () {
    // 清除定时器
    clearInterval(this.timer)
    this.timer = null
  },
  mounted () {
    // 刷新时清除定时器
    window.onunload = () => {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 输入验证码，更新验证码数据
    fnCheckCodeKeyup (e) {
      const index = e.target.dataset.index * 1
      const el = e.target
      // 解决输入e的问题
      el.value = el.value
        .replace(/Digit|Numpad/i, '')
        .slice(0, 1)
      if (/Digit|Numpad/i.test(e.code)) {
        // 必须在这里赋值，否则输入框会是空值
        this.aCheckCodeInput.splice(index, 1, e.code.replace(/Digit|Numpad/i, ''))
        el.nextElementSibling && el.nextElementSibling.focus()
        // 输入最后一个验证码时，自动失去焦点
        if (index === this.codeLength - 1) {
          if (this.aCheckCodeInput.join('').length === this.codeLength) document.activeElement.blur()
        }
        // if (index === 5) {
        //   if (this.aCheckCodeInput.join('').length === 6) document.activeElement.blur()
        // }
      }
    },
    // 输入验证码，检测位置变化
    fnCheckCodeKeydown (e) {
      let index = e.target.dataset.index * 1
      const el = e.target
      if (e.key === 'Backspace') {
        if (this.aCheckCodeInput[index].length > 0) {
          this.aCheckCodeInput.splice(index, 1, '')
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus()
            this.aCheckCodeInput[index - 1] = ''
          }
        }
      } else if (e.key === 'Delete') {
        if (this.aCheckCodeInput[index].length > 0) {
          this.aCheckCodeInput.splice(index, 1, '')
        } else {
          if (el.nextElementSibling) {
            el.nextElementSibling.focus()
            this.aCheckCodeInput[++index] = ''
          }
        }
      } else if (e.key === 'Home') {
        el.parentElement.children[0] && el.parentElement.children[0].focus()
      } else if (e.key === 'End') {
        el.parentElement.children[this.aCheckCodeInput.length - 1] &&
                    el.parentElement.children[this.aCheckCodeInput.length - 1].focus()
      } else if (e.key === 'ArrowLeft') {
        if (el.previousElementSibling) el.previousElementSibling.focus()
      } else if (e.key === 'ArrowRight') {
        if (el.nextElementSibling) el.nextElementSibling.focus()
      }
    },
    // 输入验证码，解决一个输入框输入多个字符的问题
    fnCheckCodeInputEvent (e) {
      const index = e.target.dataset.index * 1
      const el = e.target
      el.value = el.value
        .replace(/Digit|Numpad/i, '')
        .slice(0, 1)
      this.aCheckCodeInput[index] = el.value
    },
    // 验证码粘贴
    fnCheckCodeKeyPaste (e) {
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === this.codeLength) {
          this.aCheckCodePasteResult = str.split('')
          document.activeElement.blur()
          this.aCheckCodeInput = this.aCheckCodeInputComputed
          this.aCheckCodePasteResult = []
        } else {
          // 如果粘贴内容不合规，清除所有内容
          this.aCheckCodeInput = []
          for (let i = 0; i < this.codeLength; i++) {
            this.aCheckCodeInput.push('')
          }
        }
      })
    },
    resend () {
      if (!this.reSendUrl || this.isSend || this.timer) {
        return
      }
      request.get(this.reSendUrl, { timeout: 10000 }).then(res => {
        console.log(res)
        if (res.code === 200) {
          console.log(res)
        }
      }).catch(e => {
        console.log(e)
      })

      this.$toast.success('验证码已发送')
      this.second = this.totalSecond
      sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(new Date().getTime() + this.totalSecond * 1000)) // 防刷新
      this.timerDown()
    },
    timerDown () {
      this.isSend = true
      this.timer = setInterval(() => {
        this.second--
        // 倒计时结束
        if (this.second === 0) {
          clearInterval(this.timer)
          this.timer = null
          this.second = this.totalSecond
          this.isSend = false
          sessionStorage.removeItem(this.sessionStorageKey)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.g-remove-check-code {
    width: 100%;
    padding: 16px 16px 8px 16px;
}

.g-remove-check-code .g-remove-check-code_title {
    font-size: 14px;
    color: #666;
}

.g-remove-check-code .g-remove-check-code_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 28px 0 28px 0;
    margin: 0 auto;
}

.g-remove-check-code .g-remove-check-code_content input {
    width: 50px;
    aspect-ratio: 1;
    font-size: 36px;
    text-align: center;
    border: none;
    outline: none;
    border: solid 1px rgba(187, 187, 187, 100);
    border-radius: 4px;
    // -moz-appearance: textfield;
    appearance: textfield;
}

.g-remove-check-code .g-remove-check-code_content input.g-code-input_color {
    border-color: #5290FF;
}

.g-remove-check-code .g-remove-check-code_content input::-webkit-outer-spin-button,
.g-remove-check-code .g-remove-check-code_content input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.g-remove-check-code .g-remove-check-code_tip {
    color: #999;
    .code_tip_span {
    color: orangered;
    }
    .code_tip_span_disabled {
        color: orange;
    }
}

</style>
