<template>
  <div>
    <!-- 头部 NavBar -->
    <van-nav-bar left-arrow @click-left="redirect(-1)" :title="movie.title ?? ''" fixed z-index="2">
    </van-nav-bar>
    <!-- 播放器 -->
    <div class="movie-player margin-nav-fixed">
      <div class="veoDiv">
        <!-- Vue video 需要加:key属性，播放地址改变后才会重新渲染 -->
         <!-- <div v-if="movie.need_buy">
          <video class="video" controls controlslist="nodownload" preload="auto" :poster="cover" oncontextmenu="return false" :key="movie.id">
            <source src="" type="video/mp4" />
          </video>
         </div>
         <div v-else> -->
          <video class="video" controls controlslist="nodownload" preload="auto" :poster="cover" oncontextmenu="return false" :key="movie.id">
              <source v-if="!movie.need_buy && movie.movies" :src="$baseUrl + movie.movies" type="video/mp4" />
              <source v-if="!movie.need_buy && movie.urls" :src="movie.urls" type="video/mp4" />
          </video>
         <!-- </div> -->

        <!-- 加入遮罩没有 购买或不是会员的用户不能点击video -->
        <div class="mask" v-if="movie.need_buy">
          <div v-if="hasUserId ? true : false" class="warpper">
            <van-button type="primary" size="normal" @click="goBuy">积分兑换</van-button>
            <van-button type="info" size="normal" @click="goVip">升级/兑换会员</van-button>
          </div>
          <div v-else class="warpper">
            <van-button type="warning" size="normal" @click="goLogin">登录后观看</van-button>
          </div>
        </div>
      </div>
      <!-- title -->
      <van-row class="title-row van-hairline--bottom">
        <van-col span="20">
          <div class="van-ellipsis">{{ movie.title }}</div>
        </van-col>
        <van-col span="4" class="score-col">
          <div>
            <span>{{ movie.rating }} 分</span>
          </div>
        </van-col>
      </van-row>
      <van-row class="title-row van-hairline--bottom">
        <van-col span="24" class="margin-tb-5">
            <van-icon name="gem" color="#f4a460" />
            <span class="price">&nbsp;{{ movie.points }}</span>
            <span class="line_price">{{ movie.line_points }}</span>
            <!-- <span class="points_tiem">/{{ movie.points_time }}</span> -->
          </van-col>
      </van-row>
      <!-- 各种tag -->
      <van-row class="tag-row">
        <van-col span="24" class="tag-col">
          <van-tag type="success">{{ movie.year ? movie.year.name : '' }}</van-tag>
          <van-tag type="success">{{ movie.category ? movie.category.name : '' }}</van-tag>
          <van-tag type="success">{{ movie.region ? movie.region.name : '' }}</van-tag>
          <van-tag type="success" v-for="(keyword,i) in keywords" :key="i">{{ keyword }}</van-tag>
        </van-col>
      </van-row>

      <!-- 添加评论 action sheet -->
      <van-action-sheet v-model="addCommentShow" title="添加评论" class="comment-sheet">
        <div class="content">
          <van-form @submit="onSubmit">
            <van-field name="score" >
              <template #input>
                <van-rate v-model="rateValue" allow-half void-icon="star" void-color="#eee" /> {{ rating }} 分
              </template>
            </van-field>
            <van-field name="description" v-model="comment" rows="2" autosize maxlength="50" show-word-limit type="textarea" placeholder="请输入评论内容" />
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </van-action-sheet>
      <!-- 登陆 action sheet -->
      <!-- <van-action-sheet v-model="loginShow" title="请登陆" class="login-sheet">
        <div class="content">
            <van-button round block class="loginBtn" type="warning" @click="goLogin">点击登陆</van-button>
        </div>
      </van-action-sheet> -->
      <!-- 邮箱验证 action sheet -->
      <van-action-sheet v-model="verifiedShow" title="验证邮箱" class="verified-sheet">
        <div class="content">
          <div>
            <div class="title">您的注册邮箱: {{ $store.getters.email }} 尚未验证。</div>
            <div class="description">请前往邮箱验证。如没收到验证邮件，请点击下面按钮。</div>
            <div class="reverified-btn"><van-button round block class="" type="danger">重新发送</van-button></div>
          </div>
        </div>
      </van-action-sheet>

      <!-- 评论 观看 收藏 -->
      <div class="van-hairline--bottom counts">
        <van-row>
          <van-col span="8">
            <van-icon name="eye" size="20" color="#0283ED"/>
            <van-badge :content="movie.viewing_count ?? movie.views" max="999" />
          </van-col>
          <van-col span="8" @click="commentClick">
            <van-icon name="comment" size="20" color="#EF6C02"/>
            <van-badge :content="movie.comment_count ?? movie.comment" max="999" />
          </van-col>
          <van-col span="8" @click="setFavorite">
            <van-icon :name="favoriteIcon" size="20" color="#EE0924"/>
            <van-badge :content="movie.favorite_count ?? movie.favorites" max="999" />
          </van-col>
          <!-- Todo: 购买该影片 -->
        </van-row>
      </div>
    </div>
    <!-- 为你推荐 -->
    <div class="van-doc-demo-block">
      <h3 class="van-doc-demo-block__title">为你推荐</h3>
    </div>
    <van-row>
      <van-col class="" span="12" v-for="item,i in recommend" :key="item.id" @click="redirect(item.id)">
      <ListItem :item="item" :index="i" size="small" score/>
    </van-col>
    </van-row>

    <!-- 所有评论 -->
    <div class="van-doc-demo-block">
      <h3 class="van-doc-demo-block__title">所有评论</h3>
    </div>
    <van-empty v-if="comments.length ===  0" description="没有用户评论"/>
    <van-list v-else v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" class="margin-btm50">
      <CommentItem v-for="item in comments" :key="item.id" :item="item"/>
    </van-list>
  </div>
</template>

<script>
import ListItem from '@/components/movie_list_item.vue'
import CommentItem from '@/components/comment_list_item.vue'
import { getMovie, getMovieRecommend, getMovieComments, addComment, setFavorite, addViewing, onExpend } from '@/api/movie'
import { getUserScore } from '@/api/profile'
import { formatterCount } from '@/utils/commonFn'

export default {
  name: 'MovieIndex',
  data () { // 数据
    return {
      id: parseInt(this.$route.params.id),
      history: [parseInt(this.$route.params.id)],
      fullPath: this.$route.fullPath,
      movie: {},
      comment: '', // 填写评论
      rateValue: 5, // 评分
      comments: [], // 所有评论
      addCommentShow: false,
      // loginShow: false,
      verifiedShow: false,
      recommend: [], // 推荐top10
      loading: false,
      finished: false,
      viewinged: false,
      page: 1
    }
  },
  // 组件
  components: {
    ListItem,
    CommentItem
  },
  // 计算属性
  computed: {
    keywords () {
      return this.movie.keywords ? JSON.parse(this.movie.keywords) : []
    },
    favoriteIcon () {
      return this.movie.is_favorite ? 'like' : 'like-o'
    },
    rating () {
      return this.rateValue * 2
    },
    viewingCount () {
      const count = this.movie.viewing_count ?? this.movie.views
      return formatterCount(count)
    },
    commentCount () {
      const count = this.movie.comment_count ?? this.movie.comment
      return formatterCount(count)
    },
    favoriteCount () {
      const count = this.movie.favorite_count ?? this.movie.favorites
      return formatterCount(count)
    },
    hasUserId () {
      return this.$store.getters.userId ?? ''
    },
    cover () {
      return this.movie.cover ? this.$baseUrl + this.movie.cover : this.$baseUrl + this.movie.thumb
    }
  },
  // 创建阶段
  beforeCreate () {},
  created () {
    // 获取电影信息
    this.getMovie()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {
    const video = document.querySelector('video')

    video.onplay = async (event) => {
      if (!this.viewinged) {
        await addViewing(this.movie.id || 0).then(res => {
          this.viewinged = true
          this.movie.viewingCount += 1
        }).catch(error => {
          console.log(error)
        })
      }
    }
  },
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  watch: {
    movie (newValue, oldValue) {
      if (newValue) {
        this.getMovieRecommend()
        this.getComments()
      }
    },
    id (newValue, oldValue) {
      this.getMovie()
    }
  },
  methods: {
    async getMovie () { // 获取影片详情
      await getMovie(this.id).then(res => {
        this.movie = res
      }).catch(error => {
        console.log('getMovie ERROR::::', error)
      })
    },
    async getMovieRecommend () { // 获取推荐Top10
      await getMovieRecommend(this.movie.category.id, this.id).then(res => {
        this.recommend = res.filter(item => item.id !== this.id)
      }).catch(error => {
        console.log('getMovieRecommend ERROR::::', error)
      })
    },
    redirect (id) { // 跳转本页
      if (this.id === id) {
        return
      }
      if (id === -1) {
        if (this.history.length > 0) {
          this.$router.back()
          this.id = this.history.pop()
          return
        } else {
          this.$router.back()
          return
        }
      }

      this.$router.push(`/movie/${id}`)
      this.history.push(this.id)
      this.id = id
    },
    async getComments () { // 获取所有评论
      this.page = 1
      await getMovieComments(this.id, this.page).then(res => {
        this.comments = res
        this.loading = false
        this.finished = false
      }).catch(error => {
        console.log('getMovieComments ERROR::::', error)
      })
    },
    async onLoad () { // 加载评论
      this.page++
      await getMovieComments(this.id, this.page).then(res => {
        if (res.length) {
          this.comments = this.comments.concat(res)
          // 加载状态结束
          this.loading = false
        } else {
          this.finished = true
        }
      }).catch(error => {
        console.log('getMovieComments ERROR::::', error)
      })
    },
    async onSubmit (values) { // 提交评论
      try {
        await addComment(this.id, this.rating, this.comment).then(res => {
          this.addCommentShow = false
          this.movie.commentCount++
          this.rateValue = 5
          this.comment = ''
          this.getComments()
        })
      } catch (error) {
        console.log('addComment ERROR::::', error)
      }
    },
    async setFavorite () {
      if (this.hasUserId) {
        const favorite = !this.movie.is_favorite
        await setFavorite(this.id, favorite).then(res => {
          this.movie.is_favorite = favorite
          if (favorite) {
            this.movie.favoritesCount++
          } else {
            this.movie.favoritesCount--
          }
        }).catch(error => {
          console.log('setFavorite ERROR::::', error)
        })
      } else {
        // this.loginShow = true
        this.goLogin()
      }
    },
    commentClick () {
      // const isLogin = this.hasUserId
      // const isVerified = this.$store.getters.verified
      if (!this.hasUserId) {
        // this.loginShow = true
        this.$router.push('/login?backPath=' + this.fullPath)
        return
      } else {
        // if (!isVerified) {
        //   this.verifiedShow = true
        //   return
        // }
      }

      this.addCommentShow = true
    },
    goLogin () {
      this.$router.push('/login?backPath=' + this.fullPath)
    },
    // 立即购买
    async goBuy () {
      let score = 0
      await getUserScore(this.hasUserId).then(async res => {
        score = res.score
        // 判断积分是否够扣
        if (score > this.movie.points) {
          // 扣除积分
          await onExpend(this.movie.id).then(res => {
            if (res === '扣除成功') {
              this.$toast.success('兑换成功～！')
              this.movie.need_buy = !this.movie.need_buy
            }
          })
        } else {
          // 积分不足
          // 去充值页
          this.$router.push('/pay?ntfType=0')
        }
      })
    },
    // 升级或续费会员
    goVip () {
      this.$router.push('/upvip')
    }
  }
}
</script>

<style lang="less" scoped>
.video {
  width: 100vw;
  // aspect-ratio: 16/9;
  height: 56.25vw;
}
.van-row.title-row {
  line-height: 20px;
  padding: 0 10px;
  .score-col {
    text-align: right;
  }
}
.van-row.tag-row {
  .van-tag {
    margin: 5px;
  }
}
.van-hairline--bottom.counts {
  padding: 10px 16px;
  // text-align: center;
}
// .login-sheet {
//   .content {
//     .loginBtn {
//       margin: 50px 32px;
//     }
//   }
// }
.verified-sheet {
  .content {
    margin: 50px 16px;
    text-align: center;
  }
  .title {
    margin: 10px;
    font-size: 14px;
    color: brown;
  }
  .description {
    margin: 10px;
    color: slategray;
  }
  .reverified-btn {
    margin: 16px;
  }
}

.van-action-sheet {
  .van-rate {
    padding: 10px 16px;
  }
}

// msak样式,大小位置跟.veoDiv一致
.veoDiv {
  position: relative;
}
.mask
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.warpper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.van-button--normal {
  margin: 0 10px;
}
.price {
  font-size: 14px;
  color: chocolate;
}
// .line_price {
//   text-decoration: line-through solid #FF5722;
//   color: darkgray;
//   padding-left: 3px;
// }
// .points_tiem {
//   color: darkgray;
//   padding-left: 3px;
// }
</style>
