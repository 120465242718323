import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import '@/styles/common.less'
import '@/utils/vant-ui'
import { sourceUrl } from '@/utils/commonFn'

Vue.config.productionTip = false
// 使chrome的vue生效
Vue.config.devtools = true

Vue.mixin({
  created () {
    this.$baseUrl = sourceUrl
    if (this.isWechatBrowser()) {
    // if (this.isWechatBrowser() || !this.isMobileBrowser()) {
      // 如果是微信浏览器，则跳转到404页面
      router.push('/404')
    }
  },
  methods: {
    isWechatBrowser () {
      const userAgent = navigator.userAgent || window.opera
      return /micromessenger/i.test(userAgent)
    },
    isMobileBrowser () {
      const userAgent = navigator.userAgent || window.opera
      console.log(userAgent)
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    }
  }
})

new Vue({
  router,
  store,
  mounted () {},
  render: h => h(App)
}).$mount('#app')

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})
