import { render, staticRenderFns } from "./enterCode.vue?vue&type=template&id=84e813ba&scoped=true"
import script from "./enterCode.vue?vue&type=script&lang=js"
export * from "./enterCode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e813ba",
  null
  
)

export default component.exports