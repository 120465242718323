<template>
  <div>
    <!-- 头部 NavBar -->
    <van-nav-bar fixed right-text="清空" left-arrow @click-left="$router.go(-1)" @click-right="date = ''">
      <template #title>
        <div @click="dateShow = true">{{ date ? date : '选择日期区间' }}</div>
      </template>
    </van-nav-bar>
    <!-- 日期选择 -->
    <van-calendar v-model="dateShow" type="range" @confirm="onConfirm" :show-confirm="false" :min-date="minDate" :max-date="maxDate" />

    <!-- 消费列表 -->
    <van-empty v-if="list.length === 0" image="search" description="没有更多了" />

    <!-- <div v-else class="margin-nav-fixed"> -->
      <div class="van-doc-demo-block margin-nav-fixed">
        <h3 class="van-doc-demo-block__title">收藏记录：</h3>
      </div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onSearch(false)">
        <div v-for="favorite in list" :key="favorite.id" class="expend-card">
          <van-col span="24" class="van-col--24 col-24--padding" @click="$router.push(`/movie/${favorite.id}`)">
            <ListItem :item="favorite" score/>
          </van-col>
          <div class="favorite-date">{{ favorite.created_at }}</div>
        </div>
      </van-list>
    </div>
  <!-- </div> -->
</template>

<script>
import { getFavorites } from '@/api/profile'
import ListItem from '@/components/movie_list_item.vue'

export default {
  name: 'ExpendList',
  data () { // 数据
    return {
      page: 0,
      date: '',
      dateShow: false,
      minDate: new Date(2023, 1, 1),
      maxDate: new Date(),
      startDate: null,
      endDate: null,
      list: [],
      loading: false,
      finished: false
    }
  },
  // 组件
  components: {
    ListItem
  },
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.onSearch(true)
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirm (date) {
      const [start, end] = date
      this.startDate = start
      this.endDate = end
      this.dateShow = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.onSearch()
    },
    async onSearch (reload = true) {
      reload ? this.page = 1 : this.page++
      getFavorites({ start: this.startDate, end: this.endDate, page: this.page }).then(res => {
        if (res.length > 0) {
          this.list = this.list.concat(res)
          this.loading = false
          console.log('onSearch:::getFavorites:::', res)
        } else {
          this.finished = true
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.favorite-date {
  text-align: right;
  padding: 5px 16px 16px 16px;
  color: rgba(69, 90, 100, 0.6);
}
</style>
