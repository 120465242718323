<template>
  <div>
    <div class="van-doc-demo-block" v-if="title">
        <h3 class="van-doc-demo-block__title">{{ title }}</h3>
    </div>
    <van-empty v-if="list.length ===  0" description="没有更多了"/>
    <van-list v-else v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-row v-if="!small">
        <van-col span="12" v-for="(item, i) in list" :key="i" @click="$router.push(`/movie/${item.id}`)">
            <ListItem :item="item" :index="i" size="small"/>
        </van-col>
        </van-row>
        <van-row v-else>
        <van-col span="24" class="col-24--padding" v-for="item,i in list" :key="i" @click="$router.push(`/movie/${item.id}`)">
            <ListItem :item="item" score/>
        </van-col>
        </van-row>
    </van-list>
  </div>
</template>

<script>
import ListItem from '@/components/movie_list_item'

export default {
  components: { ListItem },
  data: function () {
    return {
      loading: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    finished: {
      type: Boolean,
      default: false
    },
    small: {
      typeof: Boolean,
      default: true
    }
  },
  methods: {
    onLoad () {
      this.$emit('onLoad')
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>

</style>
