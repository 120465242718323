<template>
  <div class="login">
    <van-nav-bar title="用户登陆" left-arrow @click-left="$router.go(-1)" />
    <div class="container">
      <div class="title">
        <h3>用户登陆</h3>
        <p>未注册的邮箱自动注册（请牢记登陆邮箱和密码）</p>
      </div>

      <!-- <div class="form">
        <div class="form-item">
          <input class="inp" v-model="email" placeholder="请输入邮箱" type="text"/>
        </div>
        <div class="form-item">
          <input class="inp" v-model="password" placeholder="6-12位，字母、数字组合" type="password"/>
        </div>
        <div class="form-item">
          <input class="inp" v-model="captcha_text" maxlength="4" placeholder="请输图形验证码" type="text"/>
          <img :src="base64" alt="" @click="refresh_captcha">
        </div>
      </div> -->

      <!-- <div style="padding: 0 20px;">
        <van-button type="warning" round size="large" @click="login">登 陆</van-button>
      </div> -->

      <van-form @submit="onSubmit" class="login">
        <!-- 邮箱 -->
        <van-field v-model="email" name="email" label="注册邮箱" placeholder="请输入邮箱"
          @input="()=>{passwordError = false; passwordMessage = '';}"
          :error="passwordError"
          :rules="[{ required: true, message: '请填写邮箱' }, {validator: validateEmail, message: 'Email格式不合法'}]"
        />
        <!-- 密码 -->
        <van-field v-model="password" type="password" name="password" label="登陆密码" placeholder="6-12位，字母、数字组合" maxlength="12"
          @input="()=>{passwordError = false; passwordMessage = '';}"
          :error="passwordError"
          :errorMessage="passwordMessage"
          :rules="[{ required: true, message: '请填写密码' }, {validator: validatePassword, message: '密码格式不合法'}]"
        />
        <!-- 图形验证码 -->
        <van-field ref="captcha" v-model="captcha_text" name="captcha" label="验证码" placeholder="请输图形验证码" maxlength="4"
          @input="()=>{captchaError=false;catchaMessage='';}"
          :error="captchaError"
          :errorMessage="catchaMessage"
          :rules="[{ required: true, message: '请填写图形验证码' }, {validator: validateCaptcha,  message: '图形验证码不合法'}]"
        >
          <template #button>
            <img :src="base64" alt="" @click="refresh_captcha">
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit"> 提 交 </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { captcha, login } from '@/api/auth'
// import { Dialog } from 'vant'

export default {
  name: 'LoginIndex',
  data () { // 数据
    return {
      captchaError: false,
      catchaMessage: '',
      passwordError: false,
      passwordMessage: '',

      base64: '', // 图形验证码
      captchakey: '', // 图形验证码key
      email: '', // 注册邮箱
      password: '', // 密码
      captcha_text: '' // 验证码

    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.refresh_captcha()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    // 刷新图形验证码
    async refresh_captcha () {
      const { key, image } = await captcha(this.captchakey)
      this.base64 = image
      this.captchakey = key
    },
    // 登陆
    async onSubmit (values) {
      try {
        const data = await login(this.email, this.password, this.captcha_text, this.captchakey)
        // const verified = data.verified

        const backPath = this.$route.query.backPath || ''

        this.$store.commit('user/setInfo', data)

        // if (!verified) {
        //   const path = '/EnterCode?backPath=' + backPath
        //   this.$router.replace(path)
        //   return
        // }

        if (backPath !== '') {
          this.$router.replace(backPath)
          return
        }
        this.$router.replace('/home')
      } catch (e) {
        if (e.response.status === 400 && e.response.data.error === 'login') {
          this.passwordError = true
          this.passwordMessage = '邮箱或密码不正确,请重新输入！'
        }
        if (e.response.status === 400 && e.response.data.error === 'captcha') {
          this.captchaError = true
          this.catchaMessage = '图形验证码错误！请重新输入。'
          this.$refs.captcha.focus()
        }
        this.refresh_captcha()
        this.captcha_text = ''
      }
    },
    validateEmail (val) {
      return /^([0-9a-zA-Z_.-])+@([0-9a-zA-Z_.-])+.([a-zA-Z]+)$/.test(this.email)
    },
    validatePassword (val) {
      return /^(?=.*[a-zA-Z])(?=.*\d).{6,12}$/.test(val)
    },
    validateCaptcha (val) {
      return /^[a-zA-Z0-9]{4}$/.test(val)
    }
    // validateVerifyCode (val) {
    //   return /^[0-9]{4,6}$/.test(val)
    // }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 49px 29px;

  .title {
    margin-bottom: 20px;
    text-align: center;
    h3 {
      font-size: 26px;
      font-weight: normal;
    }
    p {
      line-height: 40px;
      font-size: 14px;
      color: #b8b8b8;
    }
  }

  .login {
    .van-field img{
      width: 94px;
      height: 31px;
    }
  }

}
</style>
