/**
 * 搜索相关请求
 */
import request from '@/utils/request'

export const getSearch = (search, page) => {
  return request.post('/movie/search', {
    search,
    page
  })
}
