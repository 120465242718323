<template>
  <div>
    <!-- 头部 Navbar -->
    <van-nav-bar title="观看历史" fixed />

    <!-- 历史列表 -->
    <van-pull-refresh v-model="isLoading" @refresh="initList">
      <van-empty v-if="resendMail" image="error" :description="emptyText">
        <van-button round type="danger" class="bottom-button" @click="resend">重新发送</van-button>
      </van-empty>
      <MovieList v-else :list="list" @onLoad="onLoadPage" :finished="finished" />
    </van-pull-refresh>
  </div>
</template>

<script>
// import ListItem from '@/components/movie_list_item'
import MovieList from '@/components/movie_list'
import { viewing } from '@/api/profile'
import { resendValidataEmail } from '@/api/auth'
// import { Dialog } from 'vant'

export default {
  name: 'ViewingList',
  data () { // 数据
    return {
      list: [],
      finished: false,
      // loading: false,
      isLoading: false,
      page: 0,
      emptyText: '',
      resendMail: false
    }
  },
  // 组件
  components: {
    // ListItem,
    MovieList
  },
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.initList()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {
  },
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async onLoadPage () {
      this.emptyText = ''
      this.page++
      try {
        await viewing(this.page).then(res => {
          if (res.length) {
            this.list = this.list.concat(res)
            this.loading = false
          } else {
            this.finished = true
          }
        }).catch(e => {
          this.$toast.clear()
          this.loading = false
          this.finished = true
          if (e.response.status === 403 && e.response.data.message === 'Your email address is not verified.') {
            this.resendMail = true
            this.emptyText = '注册邮箱没验证，请前往您的注册邮箱点击验证连接进行验证。如没收到请查看‘垃圾箱’或点击重新发送按钮。'
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    initList () {
      this.page = 0
      this.list = []
      this.finished = false
      this.loading = false
      this.isLoading = false
      this.resendMail = false
      this.onLoadPage()
    },
    async resend () {
      const res = await resendValidataEmail()
      console.log(res)
    }
  }
}
</script>

<style lang="less" scoped>
  .bottom-button {
    width: 160px;
    height: 40px;
  }
</style>
