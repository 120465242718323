<template>
  <div>
    <!-- 头部 NavBar -->
    <van-nav-bar left-arrow @click-left="$router.go(-1)" title="点击兑换项" fixed>
    </van-nav-bar>

    <!-- 兑换的sheet -->
    <van-action-sheet v-model="sheetShow" title="兑换积分" class="pay-sheet">
      <div class="content" v-if="this.selected != null">
        <div>
          <div class="title">
            <span style="font-size: xxx-large;">{{ this.selected.point }}</span>
            <span>积分</span>
          </div>
          <div class="description" style="font-size: large;">{{ this.selected.name }}</div>
          <div>
            <span style="color: darkgrey;">
              <div><van-icon name="info-o" /> 你当前积分：{{ userDetails.actual_score }}</div>
              <div>如还没到期则增加到期天数</div>
            </span>
          </div>
          <div class="pay-btn">
            <van-button v-if="isEnough" :loading="exchangging" loading-text="兑换中。。。" round block class="" type="danger" @click="goExchange" :disabled="exchangging">立 即 兑 换</van-button>
            <van-button v-else round block class="" type="danger" :disabled="exchangging" url="/pay?ntfType=0">去 充 值</van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <!-- 兑换等级列表 -->
    <div class="margin-nav-fixed">
      <van-cell-group inset v-for="(level, index) in levelList" :key="index" title=" ">
        <van-cell is-link @click="onExchange(level)">

          <template #title>
            <div><span class="custom_cell_title">{{ level.name }}</span></div>
            <div><span class="custom_cell_desc" style="color: cornflowerblue;">{{ level.description }}</span></div>
          </template>
          <template #right-icon>
            <div><van-image :src="level.icon" width="60" height="30" fit="cover"/></div>
          </template>

          <template #label>
            <span class="price">{{ level.point }} 积分</span>
            <span v-if="level.discount < 100" class="line_price">{{ level.line_point }} 积分</span>
          </template>

        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getLevelList } from '@/api/pay'
import { getUserDetail } from '@/utils/storage'

export default {
  name: 'UpVip',
  data () { // 数据
    return {
      sheetShow: false,
      selected: null,
      levelList: [],
      userDetails: 0,
      exchangging: false // 兑换中
    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {
    isEnough () {
      return this.userDetails.actual_score >= this.selected.point
    }
  },
  activated () {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.getLevelList()
    this.userDetails = getUserDetail()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    // 获取等级List
    async getLevelList () {
      await getLevelList().then(res => {
        if (Object.values(res).length > 0) {
          const values = Object.values(res)
          this.levelList = values.map(item => {
            item.icon = this.$baseUrl + item.icon.substr(2)
            item.image = this.$baseUrl + item.image.substr(2)
            return item
          })
        }
        console.log('getLevelList SUCCESS::::', this.levelList)
      }).catch(err => {
        console.log('getLevelList ERROR::::', err)
      })
    },

    // 兑换
    onExchange (level) {
      // const score = this.userPoints
      this.selected = level
      this.sheetShow = true
      console.log(this.isEnough)
      console.log('exchange::::', level)
    },
    goExchange () {

    }
  }
}
</script>

<style lang="less" scoped>
  .custom_cell_title {
    font-size: 20px;
  }
  .level-icon {
    width: 60px;
    height: auto;
  }
  .pay-sheet {
  .content {
    margin: 50px 16px;
    text-align: center;
  }
  .title {
    margin: 10px;
    font-size: 14px;
    color: brown;
  }
  .description {
    margin: 10px 10px 35px 10px;
    color: slategray;
  }
  .pay-btn {
    margin: 16px;
  }
}
</style>
