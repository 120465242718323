const INFO_KEY = 'movie_app_user__info_key__'
const HISTORY_KEY = 'movie_app_user_search__history'

// 临时存储防刷新
const USER_EMAIL_KEY = 'movie_app_user_email_key__'
const USER_DETAIL_KEY = 'movie_app_user_detail_key__'

// 用户 token 登陆相关
/**
 * 获取用户信息
 * @returns { token: '', userId: '', verified: false }
 */
export const getUserInfo = () => {
  const userInfo = localStorage.getItem(INFO_KEY)
  return JSON.parse(userInfo) || { token: '', userId: '', verified: false }
}

/**
 * 设置用户信息
 * @param {*} { token: '', userId: '', verified: false }
 */
export const setUserInfo = (userInfo) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(userInfo))
}

/**
 * 移除个人信息
 */
export const removeUserInfo = () => {
  localStorage.removeItem(INFO_KEY)
}

//  用户搜索相关
/**
 * 保存用户历史搜索
 * @param {*} { categories: [], regions: [], year: [], keywords: [], search: [] }
 */
export const setHistory = (obj) => {
  const history = getHistory()
  const keys = ['categories', 'regions', 'year', 'keywords', 'search']
  keys.forEach(key => {
    if (key in obj) {
      history[key] = history[key].concat(obj[key])
      if (history[key].length > 10) {
        // 只保留最近10条
        history[key] = history[key].slice(-10)
      }
    }
  })
  localStorage.setItem(HISTORY_KEY, JSON.stringify(history))
}
/**
 * 获取用户历史搜索
 * @returns { categories: [], regions: [], year: [], keywords: [], search: [] }
 */
export const getHistory = () => {
  const history = localStorage.getItem(HISTORY_KEY)
  return JSON.parse(history) || { categories: [], regions: [], year: [], keywords: [], search: [] }
}

// 修改密码用
export const setEmail = (value) => {
  sessionStorage.setItem(USER_EMAIL_KEY, value)
}
export const getEmail = () => {
  const email = sessionStorage.getItem(USER_EMAIL_KEY)
  return email || null
}
// export const clearEmail = () => {
//   sessionStorage.removeItem(USER_EMAIL_KEY)
// }

// 用户信息
export const setUserDetail = (obj) => {
  sessionStorage.setItem(USER_DETAIL_KEY, JSON.stringify(obj))
}
export const getUserDetail = () => {
  const detail = sessionStorage.getItem(USER_DETAIL_KEY)
  return JSON.parse(detail)
}
// export const clearUserDetail = () => {
//   sessionStorage.removeItem(USER_DETAIL_KEY)
// }
