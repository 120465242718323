<template>
  <div :class="movieCls">
    <div class="item-div">
        <div class="item-img" ref="itemImg">
          <van-image lazy-load width="100%" height="100%" fit="fill" :src="size == 'small' ? (itemSource.thumb ?? itemSource.cover) : itemSource.cover" />
        </div>
        <div v-if="score" class="flag left-flag">评分：{{ item.rating }}</div>
        <div class="flag right-flag" v-if="flag">{{ flag }}</div>
    </div>
    <div class="item-title padding5px">
        <div class="van-ellipsis">{{ item.title }}</div>
        <div v-if="size == 'small'">
          <div class="margin-tb-5">
            <van-icon name="gem" color="#f4a460" />
            <span class="price">{{ item.points }}</span>
            <span class="line_price">{{ item.line_points}}</span>
          </div>
          <div class="margin-tb-5"><span class="views">观看：{{ viewingCount }}</span></div>
        </div>
        <div v-else>
          <van-col span="16" class="margin-tb-5">
            <van-icon name="gem" color="#f4a460" />
            <span class="price">{{ item.points }}</span>
            <span class="line_price">{{ item.line_points }}</span>
          </van-col>
          <van-col span="8" class="margin-tb-5"><span class="views">观看：{{ viewingCount }}</span></van-col>
        </div>

    </div>
  </div>
</template>

<script>
import { formatterCount } from '@/utils/commonFn'

export default {
  computed: {
    movieCls () {
      let cls = 'movie-item'
      // if (this.index === null) {
      //   return 'movie-item'
      // }
      // return this.index % 2 ? 'movie-item col-12--margin' : 'movie-item col-12--margin-1'
      if (this.index % 2 === 0) {
        cls += ' col-12--margin-1'
      } else {
        cls += ' col-12--margin'
      }
      cls += ' background_color_white'
      return cls
    },
    viewingCount () {
      const count = this.item.viewing_count ?? this.item.views
      return formatterCount(count)
    },
    itemSource () {
      return { thumb: this.$baseUrl + this.item.thumb, cover: this.$baseUrl + this.item.cover }
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    // 是否显示右上角角标（推荐）
    flag: {
      type: String,
      default: null
    },
    // default（单列） | small（两列）
    size: {
      type: String,
      default: 'default'
    },
    index: {
      type: Number,
      default: null
    },
    // 是否显示评分角标
    score: {
      type: Boolean,
      default: false
    }
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
  .movie-item {
    border: 1px solid #eee;
    border-radius: 6px 6px 6px 6px;
    // background-color: rgba(69, 90, 100, 0.6);
    overflow: hidden;
    .item-div {
      position: relative;
      overflow: hidden;
      .item-img {
        width: 100%;
        aspect-ratio: 16/9;
      }
      // .default {
      //     height: calc(321px * (9/16));
      //   }
      // .small {
      //   height: calc(165px * (9/16));
      // }
      .flag {
        position: absolute;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
        text-align: center;
        width: 74px;
        color: #fff;
      }
      .right-flag {
        background-color: #FF5722;
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        right: -18px;
        top: 9px;
      }
      .left-flag {
        background-color: lightsalmon;
        top: 0;
        // right: 0;
      }
    }
    .item-title {
      color: darkolivegreen;
    }
    .views {
      font-size: 10px;
      color: darkgray;
    }
  }
</style>
