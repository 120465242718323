<template>
  <div>
    <!-- 用户详情 -->
    <van-nav-bar title="会员信息" left-arrow @click-left="$router.go(-1)" fixed />
    <div class="margin-nav-fixed">
      <van-row class="avatar_row">
        <van-col span="8" offset="8">
          <UploadAvatar :avatar="avatar" @updateAvatar="updateAvatar" :avatarList="avatarList" />
        </van-col>
      </van-row>

      <van-cell-group>
        <van-cell title="VIP等级" center :value="user.level.name" :label="user.level.description">
          <template #right-icon>
            <van-image :src="user.level.icon" width="55px" height="20px" fit="cover" />
          </template>
        </van-cell>
        <van-cell title="注册邮箱" :value="user.email"/>
        <van-cell v-if="!isEdit" title="昵称" :value="user.nickname" center >
          <template #right-icon>
            <van-icon class="edit-icon" name="edit" @click="onEdit" size="20px" />
          </template>
        </van-cell>
        <van-cell v-else title="昵称">
          <template #default>
            <van-form @submit="onSubmit">
            <van-field class="nickname" ref="nickname" v-model="user.nickname" placeholder="请输入昵称" :rules="[{ required: true, message: '请填写昵称' }, {validator, message: '昵称过长，不能超过64个字符'}]" >
              <template #button>
                <van-button size="mini" type="warning" native-type="submit">保存</van-button>
              </template>
            </van-field>
          </van-form>
          </template>
        </van-cell>
        <van-cell title="充值积分" :value="user.actual_score"/>
        <van-cell title="注册时间" :value="user.created_at"/>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { getUserDetail, setUserDetail } from '@/utils/storage'
import { getAvatars, updateAvatar, updateNickname } from '@/api/profile'
import UploadAvatar from '@/components/upload-avatar.vue'

export default {
  name: 'UserDetail',
  data () { // 数据
    return {
      isEdit: false,
      user: {},
      avatar: {},
      avatarList: [{ id: 1, src: 'http://127.0.0.1/assets./images/avatars/avatar.png', checked: false }]

    }
  },
  // 组件
  components: {
    UploadAvatar
  },
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
    this.findUser()
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onEdit () {
      this.isEdit = true
      this.$nextTick(() => {
        this.$refs.nickname.focus()
      })
    },
    onSubmit () {
      updateNickname(this.user.nickname).then(res => {
        // this.$toast.success('修改成功')
        this.isEdit = false
        setUserDetail(this.user)
      })
    },
    validator (val) {
      return val.length <= 64
    },
    async findUser () {
      this.user = getUserDetail()
      if (!this.user) {
        this.user = this.$store.getters.userDetail
        setUserDetail(this.user)
      }

      const avatarList = await this.userAvatars()
      this.avatarList = avatarList.map(item => {
        // 判断当前选中的头像
        const avatarName = this.user.avatar.substring(this.user.avatar.lastIndexOf('/') + 1)
        if (item.name === avatarName) {
          this.avatar = item
          return { ...item, checked: true }
        }
        return item
      })
    },
    updateAvatar (avatar) {
      this.avatar = avatar
      if (avatar) {
        updateAvatar(avatar.id)
        this.user.avatar = avatar.src
        setUserDetail(this.user)
      }
    },
    async userAvatars () {
      const avatars = await getAvatars()
      return avatars
    }
  }
}
</script>

<style lang="less" scoped>
.avatar_row {
  margin-bottom: 16px;
}
.nickname {
  padding: 0;
}
.edit-icon {
  margin-left: 10px;
}
</style>
