<template>
  <div>
    <!-- 二级路由出口：二级组件展示的位置 -->
    <keep-alive>
        <router-view class="margin-btm50 margin-nav-fixed"></router-view>
    </keep-alive>

    <van-tabbar route>
    <!-- <van-tabbar route active-color="#ee0a24" inactive-color="#000"> -->
      <van-tabbar-item to="/home" icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item to="/category" icon="wap-nav">分类</van-tabbar-item>
      <van-tabbar-item to="/viewing" icon="underway">历史</van-tabbar-item>
      <van-tabbar-item to="/profile" icon="user">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// import { userCheck } from '@/api/auth'

export default {
  name: 'LayoutIndex',
  data () { // 数据
    return {

    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  created () {
  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
