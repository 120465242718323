import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shouldReflashCategoryKey: 0,
    shouldReflashCategiry: false
  },
  getters: {
    token: state => state.user.userInfo.token,
    userId: state => state.user.userInfo.userId,
    verified: state => state.user.userInfo.verified,
    email: state => state.user.userInfo.email,
    history: state => state.user.history,
    userDetail: state => state.user.userDetail
  },
  mutations: {
    setShouldReflashCategory (state, key) {
      state.shouldReflashCategoryKey = key
      state.shouldReflashCategiry = true
    },
    setShouldReflashCategoryFalse (state) {
      // state.shouldReflashCategoryKey = 0
      state.shouldReflashCategiry = false
    }
  },
  actions: {
  },
  modules: {
    user
  }
})
