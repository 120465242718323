<template>
  <div>
    <van-empty image="network" description="404 Not Found !!!!" />
  </div>
</template>

<script>
export default {
  name: 'NotFound404',
  data () { // 数据
    return {

    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  activated () {},
  // 创建阶段
  beforeCreate () {},
  created () {},
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
