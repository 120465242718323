<template>
  <div>
    <div v-if="item" class="comment-content">
        <div class="head">
            <van-image class="avatar" :src="item.user.avatar" round fit="cover" />
            <van-image class="icon" :src="item.user.level.icon" fit="cover" />
        </div>
        <div class="content">
            <div class="content-head">
                <div class="van-ellipsis darkgrey">{{ item.user.nickname }}</div>
                <van-rate v-model="rate" size="12" allow-half void-icon="star" void-color="#eee" />
            </div>
            <div class="description van-multi-ellipsis--l2">{{ item.description }}</div>
            <div class="foot darkgrey">
                {{ date }}
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentItem',
  data () {
    return {
      rate: this.item ? this.item.rate : 0
    }
  },
  computed: {
    date () {
      return this.item.created_at.substring(0, 10)
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.comment-content {
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    padding: 10px 16px;
    .head {
        width: 30px;
        margin-right: 10px;
    }
    .icon {
        top: -10px;
    }
    .description {
        line-height: 30px;
    }
    .content {
        width: calc(100% - 30px);
        .content-head {
            display: flex;
            justify-content: space-between;
        }
    }
    .darkgrey {
        color: darkgrey;
    }
}
</style>
