// 存放用户相关登陆，授权相关的接口请求
import request from '@/utils/request'

export const captcha = (key = '') => {
  if (key !== '') {
    return request.get(`/captcha/${key}`)
  }
  return request.get('/captcha')
}

export const resendValidataEmail = () => {
  return request.post('/email/verification-notification')
}

export const verificationEmail = (code) => {
  return request.get(`/email/verify/${code}`)
}

export const login = (email, password, captcha, captchaKey) => {
  return request.post(
    '/login', {
      email,
      password,
      captcha,
      captchaKey
    },
    {
      withCredentials: true
    }
  )
}

export const setOnline = (online = true) => {
  return request.post('/user/set/online', {
    online
  })
}

export const loginByMobile = (phone, password, captcha, captchaKey) => {
  return request.post(
    'login/cellphone', {
      phone,
      password,
      captcha,
      captchaKey
    },
    {
      withCredentials: true
    }
  )
}
