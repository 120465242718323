import { getUserInfo, setUserInfo, getHistory } from '@/utils/storage'
import { setOnline } from '@/api/auth'
export default {
  namespaced: true,
  state () {
    return {
      userDetail: {},
      userInfo: getUserInfo(),
      history: getHistory()
    }
  },
  mutations: {
    setInfo (state, obj) {
      state.userInfo = obj
      setUserInfo(obj)
      setOnline()
    },
    logout (state) {
      // 退出登录
      state.userInfo = { token: '', userId: '', verified: false }
      state.userDetail = {}
      setUserInfo(state.userInfo)
    },
    setUser (state, obj) {
      state.userDetail = obj
    }
  },
  actions: {

  },
  getters: {

  }
}
