/**
 * 用户（我的）详情页相关请求
 */
import request from '@/utils/request'

// 返回用户观看记录
export const viewing = (page = 1) => {
  return request.get(`/user/viewing/records/${page}`)
}

export const userDetails = () => {
  return request.get('/user/details')
}

export const getCounts = () => {
  return request.get('/user/counts')
}

export const getExpends = (obj) => {
  return request.post('/user/expend/records', {
    ...obj
  })
}

export const getFavorites = (obj) => {
  return request.post('/user/favorite/records', {
    ...obj
  })
}

export const getRecharges = (obj) => {
  return request.post('/user/recharge/records', {
    ...obj
  })
}

export const getAvatars = () => {
  return request.get('/user/avatars')
}

// 修改密码
export const updatePws = (newPwd, confirmPwd) => {
  return request.post('/user/change/password', {
    newPwd,
    confirmPwd
  })
}

// 修改头像
export const updateAvatar = (avatarId) => {
  return request.post('/user/update/avatar', {
    avatar_id: avatarId
  })
}

// 修改昵称
export const updateNickname = (nickname) => {
  return request.post('/user/update/nickname', {
    nickname
  })
}

// 获取用户积分
export const getUserScore = (id) => {
  return request.get('/user/points')
}
