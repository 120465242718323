import Vue from 'vue'
import VueRouter from 'vue-router'

// 登陆
import Login from '@/views/login'
import EnterCode from '@/views/login/enterCode'

// 框架
import Layout from '@/views/layout'
// 二级 首页
import Home from '@/views/layout/home'
// 分类
import Category from '@/views/layout/category'
// 我的
import Profile from '@/views/layout/profile'
// 历史浏览
import Viewing from '@/views/layout/viewing'

// 搜索页
import Search from '@/views/search'
// 视频页
import Movie from '@/views/movie'
// 支付页
import Pay from '@/views/pay'
// 升级会员
import Vip from '@/views/pay/upVip.vue'

// 用户detail
import Detail from '@/views/profile/detail'
// 用户收藏
import Favorite from '@/views/profile/favorite'
// 用户消费
import Expends from '@/views/profile/expends'
// 用户充值
import Recharges from '@/views/profile/recharges'
// 修改密码
import UpdatePWD from '@/views/profile/update-pwd'
// 404
import NotFound from '@/views/not_found.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  { path: '/EnterCode', component: EnterCode },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: '/home', component: Home }, // 首页
      { path: '/category', component: Category }, // 分类页
      { path: '/viewing', component: Viewing }, // 历史页
      { path: '/profile', component: Profile } // 我的页
    ]
  },
  { path: '/search', component: Search }, // 搜索页
  { path: '/movie/:id', component: Movie }, // 影片页
  { path: '/pay', component: Pay }, // 充值
  { path: '/upvip', component: Vip }, // 升级会员页

  { path: '/profile/detail', component: Detail }, // 我的会员信息
  { path: '/profile/favorite', component: Favorite }, // 我的收藏夹页
  { path: '/profile/expends', component: Expends }, // 我的消费记录页
  { path: '/profile/recharges', component: Recharges }, // 我的充值记录页
  { path: '/profile/update-pwd', component: UpdatePWD }, // 修改密码页
  { path: '*', component: NotFound } // 404
]

// 只注册
const authUrls = [
  '/profile',
  '/movie',
  '/profile/detail',
  '/profile/update-pwd',

  '/viewing',
  '/pay',
  '/upvip',
  '/profile/favorite',
  '/profile/expends',
  '/profile/recharges'
]
const verifiedUrls = [ // 注册并验证（手机绑定，可推荐获取积分）
  // '/viewing',
  // '/pay',
  // '/upvip',
  // '/profile/favorite',
  // '/profile/expends',
  // '/profile/recharges'
]
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    const path = to.path
    const isMoviePage = path.startsWith('/movie')
    if (isMoviePage) {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const token = store.getters.token
  const verified = store.getters.verified
  // 如果有 token 所有都可以访问
  if (token) {
    if (to.path === '/login') {
      next('/home')
      return
    }
    if (!verified) {
      if (verifiedUrls.includes(to.path)) { // 需要验证的页面
        router.push('/EnterCode?backPath=' + to.path)
        return
      }
    }

    if (verified && to.path === '/EnterCode') {
      router.push({ path: '/home', replace: true })
      return
    }
    next()
  } else { // 如果没有 token
    // 列表中的路由
    if (authUrls.includes(to.path)) {
      router.push('/login?backPath=' + to.path)
      return
    }
    if (to.path === '/EnterCode') {
      router.push('/login?backPath=/home')
      return
    }
    next()
  }

  // if (!authUrls.includes(to.path)) {
  //   if (to.path === '/login') {
  //     if (token) {
  //       next('/home')
  //     }
  //     return
  //   }
  //   next()
  //   return
  // }

  // if (token) {
  //   next()
  // } else {
  //   next('/login')
  // }
})

export default router
