<template>
  <div>
    <van-cell-group class="head">
      <!-- 头部 User card -->
      <van-card>
        <template #thumb>
          <van-image :src="userInfo.avatar" round fit="cover" />
        </template>
        <template #title>
          &nbsp;&nbsp;&nbsp;
        </template>
        <template #desc>
          <!-- nickname 等级名称 -->
          <div class="user-content">
            <div class="nickname">{{ userInfo.nickname }}</div>
            <div class="level-name" style=" margin-top: 10px;">
              <span style="margin-right: 20px;">{{ userInfo.level ? userInfo.level.name : '' }}</span>
              <span><van-icon name="gem" color="#f4a460" /> {{ userInfo.actual_score }}</span>
            </div>
          </div>
          <!-- 等级图标 -->
          <van-image class="level-icon" fit="cover" :src="userInfo.level ? userInfo.level.icon : ''"/>
        </template>
      </van-card>
    </van-cell-group>

    <!-- 充值、升级会员按钮 -->
    <van-grid column-num="3" class="lavel-upgrade-btn">
      <van-grid-item text="会员信息" to="/profile/detail">
        <template #icon>
          <van-icon name="manager" size="28" color="cornflowerblue" />
        </template>
      </van-grid-item>
      <van-grid-item text="充值" to="/pay">
        <template #icon>
          <van-icon name="card" size="28" color="deepskyblue" />
        </template>
      </van-grid-item>
      <van-grid-item text="兑换会员" to="/upvip">
        <template #icon>
          <van-icon name="gem" size="28" color="coral"/>
        </template>
      </van-grid-item>
      <!-- <van-button class="lavel-upgrade-btn" type="primary" round block color="linear-gradient(to right, #ff6034, #ee0a24)">充值</van-button>
      <van-button class="lavel-upgrade-btn" type="primary" round block color="linear-gradient(to right, #ff6034, #ee0a24)">充值</van-button> -->
    </van-grid>

    <van-cell-group class="actions">

      <van-cell title="收藏夹" is-link :value="counts.favorite" @click="$router.push('/profile/favorite')">
        <template #right-icon>
          <van-icon name="like" color="red" class="right-icon" />
          <van-icon name="arrow" class="right-icon" />
        </template>
      </van-cell>
      <van-cell title="充值记录" is-link :value="counts.recharge" @click="$router.push('/profile/recharges')">
        <template #right-icon>
          <van-icon name="gold-coin" color="coral" class="right-icon" />
          <van-icon name="arrow" class="right-icon" />
        </template>
      </van-cell>
      <van-cell title="消费记录" is-link :value="counts.expend" @click="$router.push('/profile/expends')">
        <template #right-icon>
          <van-icon name="goods-collect" color="steelblue" class="right-icon" />
          <van-icon name="arrow" class="right-icon" />
        </template>
      </van-cell>
      <van-cell title="观看记录" is-link :value="counts.viewing" @click="$router.replace('/viewing')">
        <template #right-icon>
          <van-icon name="underway" color="dodgerblue" class="right-icon" />
          <van-icon name="arrow" class="right-icon" />
        </template>
      </van-cell>
    </van-cell-group>

    <van-row class="btn-group">
      <van-col span="12">
        <van-button class="update-pws" type="info" round block color="linear-gradient(to right, rgb(237 190 124), rgb(246 155 49))"
        @click="$router.push('/profile/update-pwd')"> 修改密码 </van-button>
      </van-col>
      <van-col span="12">
        <van-button class="logout-btn" type="primary" round block color="linear-gradient(to right, rgb(178 178 178), rgb(142 141 141))"
        @click="logout"> 退出登录 </van-button>
      </van-col>
    </van-row>

  </div>
</template>

<script>
import { userDetails, getCounts } from '@/api/profile'
import { setOnline } from '@/api/auth'

export default {
  name: 'ProfileIndex',
  data () { // 数据
    return {
      userInfo: {},
      counts: {
        favorite: null,
        recharge: null,
        expend: null,
        viewing: null
      }
    }
  },
  // 组件
  components: {},
  // 计算属性
  computed: {},
  // 创建阶段
  beforeCreate () {},
  async created () {
    await userDetails().then(res => {
      this.userInfo = res
    }).catch(err => {
      console.log(err)
    })
    await getCounts().then(res => {
      this.counts = res
    }).catch(err => {
      console.log(err)
    })
    const userDetail = { ...this.userInfo, ...this.counts }
    this.$store.commit('user/setUser', userDetail)
  },
  async activated () {

  },
  // 渲染模版阶段
  beforeMount () {},
  mounted () {},
  // 视图更新
  beforeUpdate () {},
  updated () {},
  // 销毁阶段
  beforeDestroy () {},
  destroyed () {},
  methods: {
    async logout () {
      // console.log('logout', this.$store.getters.token)
      await setOnline(false)
      this.$store.commit('user/logout')
      this.$router.replace('/home')
      window.location.reload()
    }
  },
  watch: {
    // $route (to, from) {
    //   window.location.reload()
    // }
  }
}
</script>

<style lang="less" scoped>
.van-cell-group.head {
  position: fixed;
  width: 100%;
  top: 0;
}

.van-cell-group.actions {
  top: 65px;
}

.van-image.level-icon {
  top: 5px;
  right: 0;
  position: absolute;
  width: 60px;
  height: 30px;
}

.nickname {
  font-size: medium;
}

.level-name {
  color: darkgray;
}

.user-content {
  position: absolute;
  bottom: 5px;
}

.van-grid.lavel-upgrade-btn  {
  top: 60px;
}
.van-cell .right-icon {
  font-size: 16px;
  line-height: inherit;
  margin-left: 5px;
}
.btn-group {
  position: relative;
  top: 150px;
  .van-col {
    padding: 0 16px;
  }
  // width: 300px;
  // left: 37px;
}
</style>
